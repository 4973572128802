import styled from "styled-components";

const StyledEditor = styled.div`
  // width: 500px;
  height: 620px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default StyledEditor;
